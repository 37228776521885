// Here you can add other styles
:root {
--primary: #08518e;
--ten: 10;
--thr: 15;
--twe: 20;
}

body {
    font-size: 1rem;
}

.text-primary {
    color: var(--primary) !important;
}

.btn-primary {
    background-color: var(--primary);
    border-color: var(--primary);
}

.bg-primary {
    background-color: var(--primary) !important;
}

.main {
    background-color: white;
}

.main .container-fluid {
    padding:0;
}

.app-header {
    height: 129px;
}

.app-header-admin {
    background-color: var(--primary);
    height: 82px;
}

.nav-item .nav-social {
    color: var(--white);
    font-size: 30px;
}

.header-fixed .app-body {
    margin-top: 129px;
}

.header-fixed .app-body-admin {
    margin-top: 82px;
}

.header-top {
    background-color: var(--primary);
    height: 82px;
    width: 100%;
}

.app-header .navbar-brand {
    height: 82px;
}

.header-top ul {
    height: 82px;
}

.header-menu {
    height: 47px;
    width: 100%;
    background-color: #f6f4f3;
}

.header-menu ul {
    height: 47px;
}

.breadcrumb {
    margin-bottom: 0.25rem;
}

@for $i from 1 through 10 {
	$size: $i * 5;
	.mtop-#{$size} { 
		margin-top: $size + 0px;
    }
	.mbtom-#{$size} { 
		margin-bottom: $size + 0px;
    }
	.mleft-#{$size} { 
		margin-left: $size + 0px;
    }
	.mright-#{$size} { 
		margin-right: $size + 0px;
    }
}

pre {
    font-size: inherit;
    color: inherit;
    font-family: inherit;
    margin-bottom: inherit;
}

.prod-img {
    width: 100%;
    height: 300px;
    object-fit: contain;
    margin: 10px 0;
}

.comment-dname {
    width: 35px;
    height: 35px;
    background-color: #20d0d0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.comment-name {
    color: white;
    font-size: 1.6em;
    font-weight: 900;
}
